/**
 * Self executing function that triggers before the SRP background worker import.
 * It sets the serverResourceUrl for the Background worker in cases when we serve it from a CDN.
 */
(function() {
	window.serverResourceUrl = window.location.origin +
		document.querySelector("body")
				.getAttribute("data-srp-script-url")
				.replace(/\/\/.*\/login\/static/, "/login/static");

	window.upgradeResourceUrl = window.location.origin +
		document.querySelector("body")
			.getAttribute("data-pw-v2-worker-url")
			.replace(/\/\/.*\/login\/static/, "/login/static");

	window.accountPasswordUrl = window.location.origin +
		document.querySelector("body")
			.getAttribute("data-v2-password-js")
			.replace(/\/\/.*\/login\/static/, "/login/static");
})();

/*
 * Core global Vars
 */
$(function() {
	var body = $('body');

	Core.baseUrl			= body.attr('data-baseUrl');
	Core.cdnUrl			    = body.attr('data-cdnUrl');
	Core.staticUrl			= body.attr('data-staticUrl');
	Core.sharedStaticUrl 	= body.attr('data-sharedStaticUrl');
	Core.secureSupportUrl 	= body.attr('data-secureSupportUrl');
	Core.project			= body.attr('data-project');
	Core.projectUrl     	= body.attr('data-projectUrl');
	Core.locale				= body.attr('data-locale');
	Core.language			= body.attr('data-language');
	Core.region				= body.attr('data-region');
	Core.loggedIn			= body.attr('data-loggedIn');
	Core.userAgent			= body.attr('data-userAgent');
});

$(function() {

	/*
	 * Global utility function
	 */
	var body = document.getElementsByTagName('body')[0];
		body.className = body.className + ' js-enabled preload';
		$('body').removeClass('preload');

	/*
	 *passing session ID and client ID for GTM
	 */
	window.AppAnalyticsPackage = {};

	/*
	 *ensure we are in App
	 */
	if (typeof window.phoenix !== "undefined") {
		window.phoenix.connect(function () {
			AppAnalyticsPackage["clientId"] = window.phoenix.application.data.google_data.client_id;
			AppAnalyticsPackage["userId"] = window.phoenix.application.data.bnet_account_id;
		});
		/*
			Trigger window resize function if available
			- Phoenix may be slow to inject? - So retry
		 */
		(function resize(attemptsRemaining) {
			if (attemptsRemaining > 0) {
				if (window.phoenix.login && window.phoenix.login.service) {
					var body = document.body;
					var html = document.documentElement;
					var height = Math.max(body.scrollHeight, body.offsetHeight,
						html.clientHeight, html.scrollHeight, html.offsetHeight);
					var width = Math.max(body.scrollWidth, body.offsetWidth,
						html.clientWidth, html.scrollWidth, html.offsetWidth);
					window.phoenix.login.service.setContentSize({ width, height, by_resize: false});
					console.debug("Successfully sent resize to PHX.");
				} else {
					console.debug("Unable to send resize to PHX. Retrying.");
					setTimeout(function() {resize(attemptsRemaining);}, 2000 / attemptsRemaining--);
				}
			}
		})(5);
	}

	Utility.init();
});

window.Utility = {
	isApp: null,
	isMobileScreen: false,
	mobileBreakpoint: 576,
	init: function() {
		this.polyfills();
		this.isMobile = $('body').hasClass('mobile');
		this.isMobileScreen = this.checkIfMobileScreen();
		this.setupResizeEvent();
		this.submitActions();
		this.handleDisableButtons();
	},

	// disable submit button after click for all forms
	submitActions: function() {
		// submit button click listener.

		$('form').on('click', '.btn:not([rel="external"])', function(e) {
			// remove only text nodes (keeps hidden spinner present to ensure its visibility in iOS UIWebView)
			$(e.target)
				.button('loading')
				.contents().filter(function() { return this.nodeType == 3; }).remove()
		});

		/**
		 * Due to an artificial delay on iOS, we need to listen to touchend, and manually trigger a click.
		 * We also must stop clicks going through, which happen after a user dragged outside of a button.
		 */
		if (this.isMobile) {
			$('form').on('touchend', '.btn.btn-primary', function(e) {
				e.preventDefault();
				e.stopPropagation();

				// Retrieve latest touch event coordinates
				var touch = e.originalEvent.changedTouches;
				var touchX;
				var touchY;
				var offset = $(e.target).offset();

				if (touch && touch[0]) {
					touchX = touch[0].pageX;
					touchY = touch[0].pageY;
				}
				// Get coordinates of button
				var top = offset.top;
				var bottom = top + $(e.target).outerHeight();
				var left = offset.left;
				var right = left + $(e.target).outerWidth();

				// compare bounding box
				if ((touchX > left && touchX < right) && (touchY > top && touchY < bottom)) {
					$(e.target).trigger('click');
				}
			});
		}
		// IE only hacks
		if (/*@cc_on!@*/false) {
			// Blur checkbox on IE 10 because of the placeholder text bug.
			// https://twitter.com/hakanson/status/479697588908613632
			if (document.documentMode === 10) {
				var usernameField = document.getElementById("accountName");
				if (usernameField) {
					setTimeout(function() {
						usernameField.blur();
					}, 10);
				}
			}
			// Show label for IE 9 or lower
			if (typeof window.atob == "undefined") {
				$("label.control-label").show();
			}
			// Use non-skinned checkboxes in IE8.
			if (typeof window.addEventListener == "undefined") {
				$("input[type=checkbox]").addClass("css-input");
				$(".input-checkbox").hide();
			}
		}
	},
	getInputId: function (fieldId) {
		switch(fieldId) {
			case 'account_name':
				return "accountName";
			case 'log_in_submit':
				return 'submit';
			case 'authenticator_input':
				return 'authValue';
			default:
				return fieldId;
		}
	},
	setupResizeEvent: function () {
		var that = this;
		window.addEventListener("resize", function (e) {
			that.isMobileScreen = that.checkIfMobileScreen();
			var event = new window.CustomEvent("tassadar-resize", {
				detail: {
					isMobile: that.isMobileScreen
				}
			});

			this.dispatchEvent(event);
		}, false);
	},
	checkIfMobileScreen: function () {
		if(!window.matchMedia) {
			return window.innerWidth <= this.mobileBreakpoint;
		}

		return window.matchMedia("only screen and (max-width: " + this.mobileBreakpoint + "px)").matches;
	},
	handleDisableButtons: function () {
		var that = this;

		$('form').on('keyup keypress', function (e) {
			that.preventSubmit($(this).find('#submit'), e);
		});

		$('#submit').on('keyup keypress', function(e) {
			that.preventSubmit($(this), e);
		});
	},
	preventSubmit: function ($submitBtn, keyboardEvent) {
		var keyCode = keyboardEvent.keyCode || keyboardEvent.which;
		if ($submitBtn && keyCode && keyCode === 13) {
			if ($submitBtn.attr('[aria-disabled="true"]') || $submitBtn.hasClass('disabled')) {
				keyboardEvent.preventDefault();
				return false;
			}
		}
	},
	polyfills: function () {
		// custom event polyfill
		(function () {
			if ( typeof window.CustomEvent === "function" ) return false;

			function CustomEvent ( event, params ) {
				params = params || { bubbles: false, cancelable: false, detail: null };
				var evt = document.createEvent( 'CustomEvent' );
				evt.initCustomEvent( event, params.bubbles, params.cancelable, params.detail );
				return evt;
			}

			window.CustomEvent = CustomEvent;
		})();
	}
};
